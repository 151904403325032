<template>
    <div style="background:#F6F9FE;height:100%;">
        <div style="background: #fff;">
            <div class="head">
                <div class="">
                    <img style="width:171px ;height:34px;" src="../../assets/images/login/hrlogo.svg" alt="">
                </div>
                <div class="" style="display:flex;align-items: center;">
                    <p style="">已有账号，去<a href="#" @click.prevent="goLogin" style="color: #3CADFB;">登录
                        </a></p>
                    <i style="    height: 21px;
    line-height: 23px;" class="el-icon-arrow-right"></i>
                </div>
            </div>
        </div>
        <div class="" style="background:#FFF;padding:40px 110px;">
            <div>
                <div>
                    <!-- <Steps :steps="steps" :width="'100%'" :descMaxWidth="160" :current="current" @change="onChange" /> -->
                </div>
                <div>
                    <div class="yhdRegister">
                        <div class="register">
                            <div class="register-form" style="width:500px;margin:0 auto;">
                                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left">
                                    <el-form-item label="公司名称" prop="companyName">
                                        <el-input v-model="ruleForm.companyName" placeholder="请输入完整正确的公司名称"></el-input>
                                    </el-form-item>

                                    <el-form-item label="手机号" prop="mobile">
                                        <el-input v-model="ruleForm.mobile" placeholder="请输入手机号码"></el-input>
                                    </el-form-item>
                                    <el-form-item label="短信验证码" prop="code">
                                        <div class="verification">
                                            <el-input v-model="ruleForm.code" placeholder="请输入验证码"></el-input>
                                            <div v-if="time" class="verification-btn">{{time}}s后再获取</div>
                                            <div v-else class="verification-btn" @click="getCode">短信验证码</div>
                                        </div>
                                        <!-- <div class="verificationTip">*每天仅可以获取验证码6次,10分钟内仅可以获取3次 </div> -->
                                    </el-form-item>
                                    <el-form-item label="请设置密码" prop="password">
                                        <el-input v-model="ruleForm.password" placeholder="请输入6-20位字符密码" auto-complete="new-password" type="password"></el-input>
                                    </el-form-item>
                                    <el-form-item label="请确认密码" prop="repeatword">
                                        <el-input v-model="ruleForm.repeatword" placeholder="请再次输入密码" type="password"></el-input>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div style="margin:0 auto;width:300px;">
                                <el-button class="register-btn" @click="submitForm">确定</el-button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
          
<!-- <div>
      <div>
      <div style="margin-bottom:20px;">
        <span>画笔粗细：</span>
        <el-select style="width:100px;" v-model="lineWidth" placeholder="请选择">
          <el-option :label="1" :value="1"></el-option>
          <el-option :label="3" :value="3"></el-option>
          <el-option :label="6" :value="6"></el-option>
        </el-select>

        <span>画笔颜色：</span>

        <input v-model="lineColor" type="color" placeholder="" placeholder-class="input-placeholder" />

        <span>画布背景：</span>
        <input v-model="bgColor" type="color" placeholder="" placeholder-class="input-placeholder" />

        <span>是否裁剪：</span>
        <input v-model="isCrop" type="checkbox" name="">

      </div>

      <vue-esign style="border: 1px solid #ddd;" ref="esign" :width="canWidth" :height="canHeight" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" :isClearBgColor="isClearBgColor" />
      <button @click="handleReset">清空画板</button>
      <button @click="handleGenerate">生成图片</button>
    </div>

    <div>
      <img style="float:left;" :src="resultImg" alt="">
    </div>
</div> -->

        </div>

    </div>
</template>

<script>




export default {
    components: {
      
    },
    data() {
        return {
            time: 0,
            ruleForm: {
                companyName: '',
                password: '',
                repeatword: '',
                mobile: '',
                code: '',

            },
    //         canWidth: 800,//画布宽度--是不会超出父元素的宽度的--设置也不行
    //   canHeight: 300,

    //   lineWidth: 3,//画笔粗细
    //   lineColor: '#000000',//画笔颜色
    //   bgColor: '#ffffff',//画布背景
    //   isCrop: false,//是否裁剪
    //   isClearBgColor: true,//是否清空背景色

    //   resultImg: '',//生成签名图片-base64
            timer: null,
            rules: {
                companyName: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                    // {
                    //     validator: validateCompanyName, trigger: 'blur'
                    // }
                ],

                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    {
                        min: 6,
                        max: 20,
                        message: '长度在 6 到 20 个字符',
                        trigger: 'blur',
                    },
                ],
                repeatword: [
                    { required: true, message: '请再次输入密码', trigger: 'blur' },
                    {
                        min: 6,
                        max: 20,
                        message: '长度在 6 到 20 个字符',
                        trigger: 'blur',
                    },
                    {
                        validator: this.validateComfirmPwd, trigger: 'blur'
                    }
                    // { validator: validatePass, trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    {
                        // validator: validatemobile, trigger: 'blur'
                    }, {
                        min: 11, max: 11, trigger: 'blur', message: '手机号长度为11位'
                    }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ],
                // userAgree:[
                // { required: true, validator: validatorType, trigger: 'change' }
                // ]
            },
        }
    },
    methods: {
        handleReset () {
      // console.log(this.$refs.esign.$el)
      // console.log(this.$refs.esign)

      this.$refs.esign.reset()//清空画布内容

      this.lineWidth = 3
      this.lineColor = '#000000'
      this.bgColor = '#ffffff'
      this.isCrop = false
      this.resultImg = ''
    },
    goLogin(){
        this.$router.push('/login')
    },
    handleGenerate () {
      this.$refs.esign.generate().then(res => {
        console.log('图片的base64地址', res)
        console.log(this.$refs.esign)
        this.resultImg = res
      }).catch(err => {
        console.log('画布没有签字时', err)
        alert('请先完成签字！') // 画布没有签字时会执行这里 'Not Signned'
      })
    },
  
        handleCancel() { },
        handleClear() {
            this.$refs.signCanvasRef.canvasClear();
        },
        handleConfirm() {
            // 请注意，这里saveAsImg()方法，返回的是 base64 的图片格式
            let img = this.$refs.signCanvasRef.saveAsImg();
            console.log(img);

        },
        handleSave() {
            this.$refs.signCanvasRef.downloadSignImg();
        }
        ,

        submitForm() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    const res = await this.$api.resetPwd(this.ruleForm)
                    if (res.data.code == 200) {
                        this.$message.success('密码重置成功，即将跳往登录页')
                        setTimeout(() => {
                            this.$api.visitorLogout().then((res) => {
                                if (res.data.code === 200) {
                                    localStorage.removeItem('roleFrist')
                                    localStorage.removeItem('roleNumber')
                                    this.$router.push('/login')
                                }
                            });
                        }, 1000);

                    }


                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        validateComfirmPwd(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入新密码'));
            } else {
                if (this.ruleForm.repeatword !== this.ruleForm.password) {
                    callback(new Error('两次密码不一致,请检查'));
                } else {
                    callback();
                }

            }
        },
        handClose() {

        },
        agreeUser() {

        },


        async getCode() {
            if (this.ruleForm.mobile == '' || this.ruleForm.mobile.length !== 11) return this.$message.warning('请输入正确手机号')
            this.time = 60
            this.timer = setInterval(() => {
                this.time--
                if (this.time <= 0) {
                    clearInterval(this.timer)
                }
            }, 1000);
            const res = await this.$api.getVerificationCode({ mobile: this.ruleForm.mobile })
            if (res.data.code == 200) {
                this.$message.success('发送成功')
            }
        }
    }
}
</script>
 
<style lang="scss" scoped>
.yhdRegister .register-form .verification {
    display: flex;
}
.imgList {
    position: relative;
    i {
        position: absolute;
        top: 2px;
        right: 1px;
        color: #c2c4c0;
        cursor: pointer;
    }
}
.register-btn {
    background: #1a9dff;
    color: #fff;
    width: 398px;
    &:hover {
        background: #337cff;
    }
}
.next-btn {
    background: #fff;
    color: #333;
    width: 398px;
    &:hover {
        color: #1a9dff;
        border-color: #1a9dff;
    }
}

::v-deep .el-checkbox__input .el-checkbox__inner {
    border-radius: 50% !important;
}

.yhdRegister .register-form .verification-btn {
    position: absolute;
    cursor: pointer;
    right: 15px;
    color: #1a9dff;
    &:hover {
        color: #337cff;
    }
}
.yhdRegister {
    ::v-deep.el-form-item {
        display: flex;
        ::v-deep.el-input {
            width: 400px;
        }
    }
    ::v-deep.el-form-item__content {
        margin: 0 !important;
    }
    ::v-deep .el-form-item__label {
        text-align: right !important;
    }
}
.head {
    width: 1312px;
    height: 56px;
    margin: 0 auto;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.left {
    float: left;
}
.right {
    float: right;
}
.right a {
    color: #3cadfb !important;
}
.h56 {
    height: 40px;
    line-height: 40px;
}
.disabled-btn {
    pointer-events: none;
    font-size: 12px;
}
// body {
//     overflow-y: visible !important;

// }
// body::-webkit-scrollbar-thumb{
//     background: #fff !important;
// }
.user-agree {
    vertical-align: top;
    cursor: pointer;
    color: #1a9dff;
}
.user-item .el-form-item__label {
    position: relative;
    z-index: 999;
}
::v-deep.el-dialog__wrapper {
    overflow: visible !important;
}
.abow_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
}
.abow_dialog .el-dialog {
    margin: 0 auto !important;
    height: 90%;
    overflow: hidden;
}
.abow_dialog .el-dialog .el-dialog__body {
    position: absolute;
    left: 0;
    top: 54px;
    bottom: 0;
    right: 0;
    padding: 0;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
}
.register-tit {
    background: #f7f8fa;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #f5f5f5;
    background: #f7f8fa;
    padding: 10px 20px;
    font-size: 16px;
    color: #333333;
    margin: 25px 0;
}
</style>